import React, { Component } from "react";

export default class culturalprograms extends Component {
  render() {
    return (
      <div className="texts">
        <h1>Cultural Exchange Program 2023</h1>

        <p className="paragraf1">
          AG-sound is founded to promote, excel, and reinvent Latin music,
          rejuvenating rhythms and genres, with deep respect for the core of
          cultural and sentimental values of countries long established music
          tradition. This purpose is a big catalyst for establishing the
          cultural exchange program.
          <br />
          <br /> The cultural exchange program 2023, held in Denmark, offers an
          opportunity for musicians from Nordics and Latin America to exchange
          instrumental skills, interpretations, and musical understanding, in
          collaborative concerts and song-reinventions.
          <br />
          <br /> This year the young aspiring musician and sound technician,
          Arturo Navarro, will join from Cuba, while the other participants are
          already based in Nordics, with backgrounds from Denmark, Norway,
          Nicaragua, Mexico, and Cuba. If you are interested in experiencing the
          outcome of the cultural exchange, then make sure to attend one of the
          4 scheduled concerts in 2023.
          <br />
          <br />
          {/* <a
            href="https://www.kultunaut.dk/perl/arrmore/type-nynaut?ArrNr=16104016"
            target="_blank"
            rel="noreferrer noopener"
          >
            {" "}
            Alejandro Gispert & Co. - Metronomen/Copenhage, 29/January/2023{" "}
          </a> */}
          <p>
            {" "}
            Alejandro Gispert & Co. - Metronomen/Copenhage, 29/January/2023{" "}
          </p>
          <br />
          <br />
          {/* <a
            href="https://www.kultunaut.dk/perl/arrmore/type-nynaut?ArrNr=16104018"
            target="_blank"
            rel="noreferrer noopener"
          >
            {" "}
            Cuban Spectrum - Metronomen/Copenhagen, 12/February/2023{" "}
          </a> */}
          <p> Cuban Spectrum - Metronomen/Copenhagen, 12/February/2023 </p>
          <br />
          <br />
          {/* <a
            href="https://www.kultunaut.dk/perl/arrmore/type-nynaut?ArrNr=16104020"
            target="_blank"
            rel="noreferrer noopener"
          >
            {" "}
            Alejandro Gispert & Co. - Metronomen/Copenhagen, 26/February/2023{" "}
          </a> */}
          <p>
            {" "}
            Alejandro Gispert & Co. - Metronomen/Copenhagen, 26/February/2023{" "}
          </p>
          <br />
          <br />
          <p> Alejandro Gispert & Co. - Metronomen/Copenhagen, 26/March/2023</p>
        </p>
      </div>
    );
  }
}
