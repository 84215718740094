import React, { Component } from "react";
// import logo from "../pics/logito.png";
// import spectrum from "../pics/spectrumsommarljom.jpeg";
import ale2 from "../pics/ale2.jpeg";
// import gamlebyen from "../pics/gamlebyen.jpg";
import sanfiesta from "../pics/sanfiesta2.jpg";
import gamlebyenfest from "../pics/gamlebyenfest.jpeg";
import tour from "../pics/tour.jpg";
import robertnordmann2 from "../pics/robertnordman.jpg";

import katherine from "../pics/katherine.jpeg";
import spectrumpic2023 from "../pics/spectrumpic2023.jpeg";
import raciel from "../pics/raciel.jpeg";

import imageconcertlisandro from "../pics/imageconcertlisandro.jpeg";
import muchamuela from "../pics/muchamuela.jpeg";

// import aleyellow from "../pics/aleyellow.png";
import alesandvika from "../pics/alesandvika.jpg";
import drengen2 from "../pics/drengen2.png";

import bkLogo from "../pics/boernekulturhuset.png";
import sommersjov from "../pics/sommersjov.jpg";
import sommerSjovOgSalsa2 from "../pics/sommersologsalsa2.png";

export default class shows extends Component {
  render() {
    return (
      <div className="texts">
        <h1>Next Shows </h1>

        <div className="paragrafshowcontainer">
          <img
            className="paragrafsimg "
            src={sommerSjovOgSalsa2}
            alt="alejandro playing drums"
          />

          <p className="paragrafshow ">10/Aug/2024 kl. 11:00 og kl. 13:00</p>
          <p className="paragrafshow2">
            Sommer, Sol og Salsa - Gellerup Bibliotek, Aarhus
            <br />
            <br />
            <a
              href="https://www.aakb.dk/arrangementer/born/sommer-sol-og-salsa-0"
              target="_blank"
              rel="noreferrer noopener"
            >
              Visit Gellerup Bibliotek Webpage
            </a>
            <br />
          </p>
        </div>

        <h1>Previous Shows </h1>

        <div className="paragrafshowcontainer">
          <img
            className="paragrafsimg "
            src={sommersjov}
            alt="alejandro playing drums"
          />

          <p className="paragrafshow ">18/Jul/2024 kl. 16:00</p>
          <p className="paragrafshow2">
            Sommer, Sol og Salsa - Tagtterrassen Kulturstationen Vanløse, Frodes
            Folkehus, Vanløse - Copenhagen
            <br />
            <br />
            <a
              href="https://frodesfolkehus.dk/event/"
              target="_blank"
              rel="noreferrer noopener"
            >
              Visit frodesfolkehus webpage
            </a>
            <br />
          </p>
        </div>

        <div className="paragrafshowcontainer">
          <img
            className="paragrafsimg "
            src={sommersjov}
            alt="alejandro playing drums"
          />

          <p className="paragrafshow ">4/Jul/2024 kl. 13:00</p>
          <p className="paragrafshow2">
            Sommer, Sol og Salsa - Tagtterrassen Kulturstationen Vanløse, Frodes
            Folkehus, Vanløse - Copenhagen
            <br />
            <br />
            <a
              href="https://frodesfolkehus.dk/event/"
              target="_blank"
              rel="noreferrer noopener"
            >
              Visit frodesfolkehus webpage
            </a>
            <br />
          </p>
        </div>

        <div className="paragrafshowcontainer">
          <img className="paragrafsimg " src={sanfiesta} alt="bandpicture" />

          <p className="paragrafshow ">8/June/2024 kl. 16:00</p>
          <p className="paragrafshow2">
            Cuban Spectrum - San Fiesta festival, Sandvika,Norway
            <br />
            <br />
            <a
              href="https://san-fiesta.no/"
              target="_blank"
              rel="noreferrer noopener"
            >
              Visit www.san-fiesta.no
            </a>
            <br />
          </p>
        </div>

        <div className="paragrafshowcontainer">
          <img
            className="paragrafsimg "
            src="https://frodesfolkehus.dk/wp-content/uploads/2023/10/frodes-ikoner_Tegnebraet-1-1.jpg"
            alt="frodespicture"
          />

          <p className="paragrafshow ">24/Maj/2024 kl. 16:00</p>
          <p className="paragrafshow2">
            Ale'S Social Club - Frodes Folkehus, Vanløse - Copenhagen
            <br />
            <br />
            <a
              href="https://frodesfolkehus.dk/event/"
              target="_blank"
              rel="noreferrer noopener"
            >
              https://frodesfolkehus.dk/
            </a>
            <br />
          </p>
        </div>

        <div className="paragrafshowcontainer">
          <img className="paragrafsimg " src={drengen2} alt="bandpicture" />

          <p className="paragrafshow ">
            19/April/2024 kl. 12:00 og <br /> <br /> 20/April/2024 kl. 13:00
          </p>
          <p className="paragrafshow2">
            Drengen Der Mistede Sin Stemme - at Gellerup Bibliotek, Aarhus
            <br />
            <br />
            funded by <img src={bkLogo} alt="bkLogo" />
            <br />
          </p>
        </div>

        <div className="paragrafshowcontainer">
          <img className="paragrafsimg " src={drengen2} alt="bandpicture" />

          <p className="paragrafshow ">
            19/April/2024 kl. 10:00 og <br /> <br /> 20/April/2024 kl. 11:00
          </p>
          <p className="paragrafshow2">
            Drengen Der Mistede Sin Stemme - at Dokk1, Aarhus
            <br />
            <br />
            funded by <img src={bkLogo} alt="bkLogo" />
            <br />
          </p>
        </div>

        <div className="paragrafshowcontainer">
          <img className="paragrafsimg " src={drengen2} alt="bandpicture" />

          <p className="paragrafshow ">7/April/2024 kl. 11:00</p>
          <p className="paragrafshow2">
            Drengen Der Mistede Sin Stemme - at Casa Latinoamericana, Copenhagen
            <br />
            <br />
            <br />
          </p>
        </div>

        <div className="paragrafshowcontainer">
          <img className="paragrafsimg " src={drengen2} alt="bandpicture" />

          <p className="paragrafshow ">
            10/March/2024 kl. 13:30 og <br /> kl. 14:30
          </p>
          <p className="paragrafshow2">
            Drengen Der Mistede Sin Stemme - at Absalon Kirke, Copenhagen
            <br />
            <br />
            <br />
          </p>
        </div>

        <div className="paragrafshowcontainer">
          <img className="paragrafsimg " src={drengen2} alt="bandpicture" />

          <p className="paragrafshow ">
            27/February/2024 kl. 10:00 og <br /> kl. 11:00
          </p>
          <p className="paragrafshow2">
            Drengen Der Mistede Sin Stemme - at Kulturhuset Pilegården
            <br />
            <br />
            <br />
          </p>
        </div>

        <div className="paragrafshowcontainer">
          <img className="paragrafsimg " src={drengen2} alt="bandpicture" />

          <p className="paragrafshow ">
            20/February/2024 kl. 10:00 og <br /> kl. 11:00
          </p>
          <p className="paragrafshow2">
            Drengen Der Mistede Sin Stemme - at Kulturhuset Pilegården,
            Copenhagen
            <br />
            <br />
            <br />
          </p>
        </div>

        <div className="paragrafshowcontainer">
          <img className="paragrafsimg " src={drengen2} alt="bandpicture" />

          <p className="paragrafshow ">2/December/2023 kl. 11:00</p>
          <p className="paragrafshow2">
            Drengen Der Mistede Sin Stemme - at Kulturstationen Vanløse
            <br />
            <br />
            <br />
            {/* 
            <a
              href="https://fb.me/e/2xQESi8aX"
              target="_blank"
              rel="noreferrer noopener"
            >
              {" "}
              Facebook-Event{" "}
            </a> */}
          </p>
        </div>

        <div className="paragrafshowcontainer">
          <img className="paragrafsimg " src={drengen2} alt="bandpicture" />

          <p className="paragrafshow ">
            1/December/2023 kl. 9:45 og <br /> kl.11:00
          </p>
          <p className="paragrafshow2">
            Drengen Der Mistede Sin Stemme - at Kulturstationen Vanløse
            <br />
            <br />
            <br />
            {/* 
            <a
              href="https://fb.me/e/2xQESi8aX"
              target="_blank"
              rel="noreferrer noopener"
            >
              {" "}
              Facebook-Event{" "}
            </a> */}
          </p>
        </div>

        <div className="paragrafshowcontainer">
          <img className="paragrafsimg " src={drengen2} alt="bandpicture" />

          <p className="paragrafshow ">
            21/November/2023 kl. 9:45 og <br /> kl.11:00
          </p>
          <p className="paragrafshow2">
            Drengen Der Mistede Sin Stemme - at Kulturstationen Vanløse
            <br />
            <br />
            <br />
            {/* 
            <a
              href="https://fb.me/e/2xQESi8aX"
              target="_blank"
              rel="noreferrer noopener"
            >
              {" "}
              Facebook-Event{" "}
            </a> */}
          </p>
        </div>

        <div className="paragrafshowcontainer">
          <img className="paragrafsimg " src={drengen2} alt="bandpicture" />

          <p className="paragrafshow ">
            21/october/2023 kl.11:00 og <br /> kl.13:00
          </p>
          <p className="paragrafshow2">
            Drengen Der Mistede Sin Stemme - at Valby Kulturhus
            <br />
            <br />
            <br />
            <a
              href="https://www.facebook.com/events/113769798478618/113769805145284/"
              target="_blank"
              rel="noreferrer noopener"
            >
              {" "}
              Facebook-Event{" "}
            </a>
            <br />
          </p>
        </div>

        <div className="paragrafshowcontainer">
          <img className="paragrafsimg " src={alesandvika} alt="bandpicture" />

          <p className="paragrafshow ">24/September/2023 kl. 20:00</p>
          <p className="paragrafshow2">
            Alejandro Gispert & Co. - Metronomen/Copenhagen
            <br />
            <br />
            <br />
            {/* <a
              href="https://www.odsherredkalenderen.dk/perl/arrmore/type-nynaut?ArrNr=16295603"
              target="_blank"
              rel="noreferrer noopener"
            >
              {" "}
              Event-KultuNaut{" "}
            </a>
            <br />
            <a
              href="https://fb.me/e/2xQESi8aX"
              target="_blank"
              rel="noreferrer noopener"
            >
              {" "}
              Facebook-Event{" "}
            </a> */}
          </p>
        </div>

        <div className="paragrafshowcontainer">
          <img className="paragrafsimg " src={drengen2} alt="bandpicture" />

          <p className="paragrafshow ">19/august/2023 kl. 13:00</p>
          <p className="paragrafshow2">
            Drengen Der Mistede Sin Stemme - at Metronomen
            <br />
            <br />
            <br />
            <a
              href="https://www.facebook.com/events/968337514378665/"
              target="_blank"
              rel="noreferrer noopener"
            >
              {" "}
              Facebook-Event{" "}
            </a>
            <br />
          </p>
        </div>

        <div className="paragrafshowcontainer">
          <img className="paragrafsimg " src={drengen2} alt="bandpicture" />

          <p className="paragrafshow ">5/august/2023 kl. 13:00</p>
          <p className="paragrafshow2">
            Drengen Der Mistede Sin Stemme - at Metronomen
            <br />
            <br />
            <br />
            <a
              href="https://www.facebook.com/events/9607023356038718/"
              target="_blank"
              rel="noreferrer noopener"
            >
              {" "}
              Facebook-Event{" "}
            </a>
            <br />
          </p>
        </div>

        <div className="paragrafshowcontainer">
          <img className="paragrafsimg " src={muchamuela} alt="bandpicture" />

          <p className="paragrafshow ">Friday 7/Juli/2023 kl. 20:00</p>
          <p className="paragrafshow2">
            Alejandro Gispert & Co. - Metronomen/Copenhagen
            <br />
            "Mucha Muela" Cuban Jazz trio
            <br />
            <br />
            <a
              href="https://www.facebook.com/events/926378505294308/?ref=newsfeed"
              target="_blank"
              rel="noreferrer noopener"
            >
              {" "}
              Facebook-Event{" "}
            </a>
          </p>
        </div>

        <div className="paragrafshowcontainer">
          <img
            className="paragrafsimg "
            src={imageconcertlisandro}
            alt="bandpicture"
          />

          <p className="paragrafshow ">26/March/2023 kl. 20:00</p>
          <p className="paragrafshow2">
            Alejandro Gispert & Co. - Metronomen/Copenhagen
            <br />
            <br />
            <br />
            <br />
            <a
              href="https://fb.me/e/2xQESi8aX"
              target="_blank"
              rel="noreferrer noopener"
            >
              {" "}
              Facebook-Event{" "}
            </a>
          </p>
        </div>

        <div className="paragrafshowcontainer">
          <img className="paragrafsimg " src={raciel} alt="bandpicture" />

          <p className="paragrafshow ">26/February/2023 kl. 20:00</p>
          <p className="paragrafshow2">
            Alejandro Gispert & Co. - Metronomen/Copenhagen
            <br />
            <br />
            <br />
            <a
              href="https://www.facebook.com/events/552716862915879/"
              target="_blank"
              rel="noreferrer noopener"
            >
              {" "}
              Facebook-Event{" "}
            </a>
          </p>
        </div>

        <div className="paragrafshowcontainer">
          <img
            className="paragrafsimg "
            src={spectrumpic2023}
            alt="bandpicture"
          />

          <p className="paragrafshow ">12/February/2023 kl. 13:00</p>
          <p className="paragrafshow2">
            Cuban Spectrum - Metronomen/Copenhagen
            <br />
            <br />
            <br />
            <a
              href="https://www.facebook.com/events/1211663069563523/"
              target="_blank"
              rel="noreferrer noopener"
            >
              {" "}
              Facebook-Event{" "}
            </a>
          </p>
        </div>

        <div className="paragrafshowcontainer">
          <img className="paragrafsimg " src={katherine} alt="bandpicture" />

          <p className="paragrafshow ">29/January/2023 kl. 20:00</p>
          <p className="paragrafshow2">
            Alejandro Gispert & Co. - Metronomen/Copenhagen
            <br />
            <br />
            <br />
            <a
              href="https://www.facebook.com/events/881996589643048/"
              target="_blank"
              rel="noreferrer noopener"
            >
              {" "}
              Facebook-Event{" "}
            </a>
          </p>
        </div>

        <div className="paragrafshowcontainer">
          <img className="paragrafsimg " src={tour} alt="bandpicture" />

          <p className="paragrafshow ">01/September/2022</p>
          <p className="paragrafshow2">
            Cuban Spectrum - Pascal Norges festival in Gjøvik
            <br />
            <br />
            <br />
            <a href="https://www.facebook.com/pascalnorge/"> Festival-Page </a>
          </p>
        </div>

        <div className="paragrafshowcontainer">
          <img
            className="paragrafsimg "
            src={gamlebyenfest}
            alt="bandpicture"
          />

          <p className="paragrafshow ">27/august/2022</p>
          <p className="paragrafshow2">
            Cuban Spectrum - Gamlebyen Festival in Fredikstad/Norway
            <br />
            <br />
            <br />
          </p>
        </div>

        <div className="paragrafshowcontainer">
          <img
            className="paragrafsimg "
            src={robertnordmann2}
            alt="bandpicture"
          />

          <p className="paragrafshow ">10/august/2022</p>
          <p className="paragrafshow2">
            Cuban Spectrum - Robert Normann Festival in Sarpsborg/Norway
            <br />
            <br />
            <br />
          </p>
        </div>

        <div className="paragrafshowcontainer">
          <img className="paragrafsimg " src={sanfiesta} alt="bandpicture" />

          <p className="paragrafshow ">11/June/2022</p>
          <p className="paragrafshow2">
            Cuban Spectrum - Sandvika Internasjonale Kulturfestival in
            Sandvika/Norway
            <br />
            <br />
            <br />
          </p>
        </div>

        <div className="paragrafshowcontainer">
          <img className="paragrafsimg " src={ale2} alt="bandpicture" />

          <p className="paragrafshow ">27/march/2022</p>
          <p className="paragrafshow2">
            Cuban Spectrum - Ibsenhuset in Skien/Norway
            <br />
            <br />
            <br />
            {/* <a href="https://fb.me/e/29t0W6qJP"> Facebook-Event </a> */}
          </p>
        </div>
        {/* <div className="paragrafshowcontainer">
          <img className="paragrafsimg " src={spectrum} alt="bandpicture" />

          <p className="paragrafshow ">27/march/2022</p>
          <p className="paragrafshow2">
            Cuban Spectrum - Ibsenhuset in Skien/Norway
            <br />
            <br />
            <br />
            <a href="https://fb.me/e/29t0W6qJP"> Facebook-Event </a>
          </p>
        </div> */}
      </div>
    );
  }
}
