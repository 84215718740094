import singing from "./pics/singin.jpeg";
// import spectrumpic from "./pics/spectrumsommarljom.jpeg";
import alechat from "./pics/alechat.jpg";
import guitar from "./pics/guitar.jpg";
import CEP2023 from "./pics/CEP2023.png";

import "./App.css";
import Nav from "./pages/nav";
import "bootstrap/dist/css/bootstrap.min.css";
import Texts from "./components/texts";
import Home from "./components/home";
import React from "react";
import Sound from "./components/sound";
import Solo from "./components/solo";
import Shows from "./components/shows";
import Spectrum from "./components/spectrum";
import Videos from "./components//video";
import Lessons from "./components/lessons";
import Culturalprograms from "./components/culturalprograms";
import Drengendermistedesinstemme from "./components/drengendermistedesinstemme";

import PR from "./components/pr";
import CD from "./components/cd";
// import ReactPlayer from "react-player/lazy";

import { ImFacebook2 } from "react-icons/im";
// import { AiFillYoutube } from "react-icons/ai";
import { SiLinkedin } from "react-icons/si";
import { GrInstagram } from "react-icons/gr";
import { GoMailRead } from "react-icons/go";
// import { BsWhatsapp } from "react-icons/bs";
// import ReactWhatsapp from "react-whatsapp";
import FloatingWhatsApp from "react-floating-whatsapp";
import spectrum2 from "./pics/spectrumsommarljom2.jpeg";
// import pix from "./pics/pix.mp4";
// import pix3 from "./pics/pix3.mov";
import aleyellow from "./pics/aleyellow.png";
import drengen from "./pics/drengen.png";
import drengen3 from "./pics/drengen3.png";
import drengen4 from "./pics/drengen4.png";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      homepressed: true,
      biopressed: false,
      soundpressed: false,
      videopressed: false,
      spectrumpressed: false,
      solopressed: false,
      showspressed: false,
      lessonspressed: false,
      prpressed: false,
      cdpressed: false,
      culturalprogramspressed: false,
      drengendermistedesinstemmepressed: false,

      onYoutube: true,
      onSpotify: false,
    };
  }
  homeClick = (e) => {
    this.setState({
      homepressed: !this.state.homepressed,
      biopressed: false,
      soundpressed: false,
      videopressed: false,
      spectrumpressed: false,
      solopressed: false,
      showspressed: false,
      lessonspressed: false,
      culturalprogramspressed: false,
      drengendermistedesinstemmepressed: false,
      prpressed: false,
      cdpressed: false,
    });
    if (
      this.state.biopressed === false &&
      this.state.soundpressed === false &&
      this.state.videopressed === false &&
      this.state.spectrumpressed === false &&
      this.state.solopressed === false &&
      this.state.showspressed === false &&
      this.state.lessonspressed === false &&
      this.state.culturalprogramspressed === false &&
      this.state.drengendermistedesinstemmepressed === false &&
      this.state.prpressed === false &&
      this.state.cdpressed === false
    ) {
      this.setState({ homepressed: true });
    }
  };
  bioClick = (e) => {
    this.setState({
      biopressed: !this.state.biopressed,
      homepressed: false,
      soundpressed: false,
      videopressed: false,
      spectrumpressed: false,
      solopressed: false,
      showspressed: false,
      lessonspressed: false,
      culturalprogramspressed: false,
      drengendermistedesinstemmepressed: false,
      prpressed: false,
      cdpressed: false,
    });
  };
  soundClick = (e) => {
    this.setState({
      soundpressed: !this.state.soundpressed,
      homepressed: false,
      biopressed: false,
      videopressed: false,
      spectrumpressed: false,
      solopressed: false,
      showspressed: false,
      lessonspressed: false,
      culturalprogramspressed: false,
      drengendermistedesinstemmepressed: false,
      prpressed: false,
      cdpressed: false,
    });
  };
  videoClick = (e) => {
    this.setState({
      videopressed: !this.state.videopressed,
      homepressed: false,
      biopressed: false,
      soundpressed: false,
      spectrumpressed: false,
      solopressed: false,
      showspressed: false,
      lessonspressed: false,
      culturalprogramspressed: false,
      drengendermistedesinstemmepressed: false,

      prpressed: false,
      cdpressed: false,
    });
  };

  spectrumClick = (e) => {
    this.setState({
      spectrumpressed: !this.state.spectrumpressed,
      homepressed: false,
      biopressed: false,
      soundpressed: false,
      videopressed: false,
      solopressed: false,
      showspressed: false,
      lessonspressed: false,
      culturalprogramspressed: false,
      drengendermistedesinstemmepressed: false,
      prpressed: false,
      cdpressed: false,
    });
  };
  soloClick = (e) => {
    this.setState({
      solopressed: !this.state.solopressed,
      homepressed: false,
      biopressed: false,
      soundpressed: false,
      videopressed: false,
      spectrumpressed: false,
      showspressed: false,
      lessonspressed: false,
      culturalprogramspressed: false,
      drengendermistedesinstemmepressed: false,
      prpressed: false,
      cdpressed: false,
    });
  };

  showsClick = (e) => {
    this.setState({
      showspressed: !this.state.showspressed,
      homepressed: false,
      biopressed: false,
      soundpressed: false,
      videopressed: false,
      spectrumpressed: false,
      solopressed: false,
      lessonspressed: false,
      culturalprogramspressed: false,
      drengendermistedesinstemmepressed: false,
      prpressed: false,
      cdpressed: false,
    });
  };

  lessonsClick = () => {
    this.setState({
      lessonspressed: !this.state.lessonspressed,

      homepressed: false,
      biopressed: false,
      soundpressed: false,
      videopressed: false,
      spectrumpressed: false,
      solopressed: false,
      culturalprogramspressed: false,
      drengendermistedesinstemmepressed: false,
      showspressed: false,
      prpressed: false,
      cdpressed: false,
    });
  };

  culturalprogramsClick = () => {
    this.setState({
      culturalprogramspressed: !this.state.culturalprogramspressed,

      homepressed: false,
      biopressed: false,
      soundpressed: false,
      videopressed: false,
      spectrumpressed: false,
      solopressed: false,
      lessonspressed: false,
      showspressed: false,
      drengendermistedesinstemmepressed: false,
      prpressed: false,
      cdpressed: false,
    });
  };

  drengendermistedesinstemmeClick = () => {
    this.setState({
      drengendermistedesinstemmepressed:
        !this.state.drengendermistedesinstemmepressed,

      homepressed: false,
      biopressed: false,
      soundpressed: false,
      videopressed: false,
      spectrumpressed: false,
      solopressed: false,
      lessonspressed: false,
      showspressed: false,
      culturalprogramspressed: false,
      prpressed: false,
      cdpressed: false,
    });
  };

  prClick = () => {
    this.setState({
      prpressed: !this.state.prpressed,
      homepressed: false,
      biopressed: false,
      soundpressed: false,
      videopressed: false,
      spectrumpressed: false,
      solopressed: false,
      showspressed: false,
      lessonspressed: false,
      culturalprogramspressed: false,
      drengendermistedesinstemmepressed: false,
      cdpressed: false,
    });
  };

  cdClick = () => {
    this.setState({
      cdpressed: !this.state.cdpressed,
      homepressed: false,
      biopressed: false,
      soundpressed: false,
      videopressed: false,
      spectrumpressed: false,
      solopressed: false,
      showspressed: false,
      lessonspressed: false,
      culturalprogramspressed: false,
      drengendermistedesinstemmepressed: false,
      prpressed: false,
    });
  };

  onYoutubehandler = () => {
    this.setState({
      onYoutube: !this.state.onYoutube,
    });

    if (this.state.onYoutube === false) {
      this.setState({ onSpotify: false });
    } else {
      this.setState({ onSpotify: true });
    }
  };

  onSpotifyhandler = () => {
    this.setState({
      onSpotify: !this.state.onSpotify,
    });

    if (this.state.onSpotify === false) {
      this.setState({ onYoutube: false });
    } else {
      this.setState({ onYoutube: true });
    }
  };

  render() {
    return (
      <div className="containerApp">
        <Nav
          homeClick={this.homeClick}
          bioClick={this.bioClick}
          soundClick={this.soundClick}
          videoClick={this.videoClick}
          spectrumClick={this.spectrumClick}
          soloClick={this.soloClick}
          showsClick={this.showsClick}
          lessonsClick={this.lessonsClick}
          culturalprogramsClick={this.culturalprogramsClick}
          drengendermistedesinstemmeClick={this.drengendermistedesinstemmeClick}
          prClick={this.prClick}
          cdClick={this.cdClick}
        />

        <div className="App">
          <header className="App-header">
            {this.state.biopressed === true && (
              <>
                {" "}
                <Texts />
                <img
                  src={singing}
                  className="picstransition3"
                  width="50%"
                  alt="singing"
                  id="img1"
                />
              </>
            )}

            {this.state.homepressed === true && (
              <div id="homefull">
                {" "}
                <Home id="homefull" soundClick={this.soundClick} />
                <div className="picstransition3 screen" onClick={this.bioClick}>
                  {/* <ReactPlayer
                    url={pix3}
                    width="640"
                    height="360"
                    playing={true}
                    loop={true}
                    pip={false}
                    stopOnUnmount={true}
                    light={false}
                    controls={false}
                    id="firstpic"
                  /> */}
                  <img
                    src={aleyellow}
                    width="50%"
                    alt="singing"
                    id="firstpic"
                  />
                </div>
                <div className="picstransition3 screen" onClick={this.bioClick}>
                  <img
                    src={aleyellow}
                    className="picstransition3"
                    width="50%"
                    alt="singing"
                    id="secondpic"
                  />
                </div>
              </div>
            )}

            {this.state.solopressed === true && (
              <>
                {" "}
                <Solo />
                <img
                  src={singing}
                  className="picstransition"
                  width="50%"
                  alt="singing"
                />
              </>
            )}

            {this.state.showspressed === true && (
              <>
                {" "}
                <Shows />
                <img
                  src={singing}
                  className="picstransition respons"
                  width="50%"
                  alt="singing"
                />
              </>
            )}

            {this.state.soundpressed === true && (
              <Sound
                onYoutubehandler={this.onYoutubehandler}
                onSpotifyhandler={this.onSpotifyhandler}
                onYoutube={this.state.onYoutube}
                onSpotify={this.state.onSpotify}
              />
            )}

            {this.state.drengendermistedesinstemmepressed === true && (
              <>
                {" "}
                <Drengendermistedesinstemme />
                <img
                  src={drengen4}
                  className="picstransition1"
                  width="50%"
                  alt="spectrum"
                />
                <img
                  src={drengen}
                  className="picstransition1"
                  width="50%"
                  alt="spectrum"
                />
                <img
                  src={drengen3}
                  className="picstransition1"
                  width="50%"
                  alt="spectrum"
                />
              </>
            )}

            {this.state.spectrumpressed === true && (
              <>
                {" "}
                <Spectrum />
                <img
                  src={spectrum2}
                  className="picstransition1"
                  width="50%"
                  alt="spectrum"
                />
                <p className="font-small">Photo: Jacob_Stærk</p>
              </>
            )}

            {this.state.videopressed === true && <Videos />}

            {this.state.lessonspressed === true && (
              <>
                {" "}
                <Lessons />
                <img
                  src={guitar}
                  className="picstransition1"
                  width="50%"
                  alt="spectrum"
                />
              </>
            )}

            {this.state.culturalprogramspressed === true && (
              <>
                {" "}
                <Culturalprograms />
                <img
                  src={CEP2023}
                  className="picstransition1"
                  width="50%"
                  alt="spectrum"
                />
              </>
            )}

            {this.state.prpressed === true && <PR />}
            {this.state.cdpressed === true && <CD />}
          </header>

          {!this.state.homepressed === true && (
            <div className="activebuttons">
              <a
                href={
                  "mailto:" +
                  "alejandrogispert99@gmail.com" +
                  "?subject=Contact%20Form%20Ale`s%20webpage&body=Hi%20Alejandro,"
                }
                target="_blank"
                rel="noreferrer"
              >
                <GoMailRead className="mediabtns2" />{" "}
              </a>
              <a href="https://www.facebook.com/alejandro.gispert/">
                <ImFacebook2 className="mediabtns2" />{" "}
              </a>
              {/* <ReactWhatsapp
                className="mediabtns whatsapp"
                number="+45-4244-8217"
                message="Hi from Ale`s webpage!"
              >
                {" "}
                <BsWhatsapp className="mediabtns" />
              </ReactWhatsapp>{" "} */}
              <a href="https://www.linkedin.com/in/alejandro-gispert/">
                {" "}
                <SiLinkedin className="mediabtns2" />{" "}
              </a>
              <a href="https://www.instagram.com/ag_navarro/?hl=en">
                {" "}
                <GrInstagram className="mediabtns2" />{" "}
              </a>
              <FloatingWhatsApp
                className="mediabtns2 whatsappButton"
                phoneNumber="+4542448217"
                accountName="Alejandro Gispert"
                allowClickAway
                notification
                notificationDelay={120000} // 2 minutes
                notificationSound
                avatar={alechat}
                chatMessage={"Hello there! 🤝 \nHow can I help?"}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default App;
