import React, { Component } from "react";
import singing from "../pics/singin.jpeg";
import alebass from "../pics/alebass.jpeg";
import mre from "../pics/mre.jpg";
import mre2 from "../pics/mre2.jpg";
import mve3 from "../pics/mve3.jpg";
import sommarljomcomplete from "../pics/sommarljomcomplete.jpeg";
import logito1 from "../pics/logito1.png";
import asker from "../pics/asker.jpg";

import singingdown from "../pics/singinDown.jpeg";
import alebassdown from "../pics/alebassdown.jpeg";
import mredown from "../pics/mredown.jpg";
import mre2down from "../pics/mre2down.jpg";
import mve3down from "../pics/mve3down.jpg";
import askerdown from "../pics/askerdown.jpg";

import logito1down from "../pics/logito1down.png";

import sommarljomcompletedown from "../pics/sommarljomcompletedown.jpeg";

import { saveAs } from "file-saver";

// import { HiDownload } from "react-icons/hi";

export default class pr extends Component {
  render() {
    const downloadImage = () => {
      saveAs(singing, "Alejandro.jpg"); // Put your image url here.
    };

    const downloadImage2 = () => {
      saveAs(alebass, "Alejandro_Bass_0.jpg"); // Put your image url here.
    };
    const downloadImage3 = () => {
      saveAs(mre, "Alejandro_Bass_1.jpg"); // Put your image url here.
    };
    const downloadImage4 = () => {
      saveAs(mre2, "Alejandro_Bass_2.jpg"); // Put your image url here.
    };

    const downloadImage5 = () => {
      saveAs(mve3, "Alejandro_Bass_3.jpg"); // Put your image url here.
    };
    const downloadImage6 = () => {
      saveAs(sommarljomcomplete, "Cuban_Spectrum_Band_1.jpg"); // Put your image url here.
    };

    const downloadImage7 = () => {
      saveAs(logito1, "Alejandro_logo"); // Put your image url here.
    };

    const downloadImage8 = () => {
      saveAs(asker, "Alejandro_la_gozadera"); // Put your image url here.
    };

    return (
      <div className="textsopen">
        {/* <h1 className="prh1">PR </h1> */}

        <div className="prpicscontainer">
          <img
            className="m-2 shadow-sm contributions"
            width="20%"
            alt="logo"
            src={logito1down}
            rounded="true"
            onClick={downloadImage7}
          ></img>
          {/* <p className="font-small ">Photo: Jakob Stærk</p> */}

          <img
            className="m-2 shadow-sm contributions"
            width="20%"
            alt="Alesinging"
            src={singingdown}
            rounded="true"
            onClick={downloadImage}
          />
          {/* <p className="font-small ">Photo: Jakob Stærk</p> */}

          <img
            className="m-2 shadow-sm contributions"
            width="20%"
            alt="Alebass"
            src={alebassdown}
            rounded="true"
            onClick={downloadImage2}
          />
          <img
            className="m-2 shadow-sm contributions"
            width="20%"
            alt="Alebass"
            src={mredown}
            rounded="true"
            onClick={downloadImage3}
          />
          <img
            className="m-2 shadow-sm contributions"
            width="20%"
            alt="Alebass"
            src={mre2down}
            rounded="true"
            onClick={downloadImage4}
          />

          <img
            className="m-2 shadow-sm contributions"
            width="20%"
            alt="Alebass"
            src={mve3down}
            rounded="true"
            onClick={downloadImage5}
          />

          <img
            className="m-2 shadow-sm contributions"
            width="20%"
            alt="sommarljom"
            src={sommarljomcompletedown}
            rounded="true"
            onClick={downloadImage6}
          />

          <img
            className="m-2 shadow-sm contributions"
            width="20%"
            alt="asker bass"
            src={askerdown}
            rounded="true"
            onClick={downloadImage8}
          />
        </div>

        {/* <p className="paragraf1">
          
        </p> */}
      </div>
    );
  }
}
