import React, { Component } from "react";

export default class lessons extends Component {
  render() {
    return (
      <div className="texts">
        <h1>
          As a musician it is a privilege to pass on knowledge and skills to
          other aspiring artists.
        </h1>

        <p className="paragraf1">
          Cuban/Norwegian Alejandro has nearly 10 years experience teaching and
          inspiring guitarists and bass players in the Nordics, with diverse
          musical backgrounds, learning styles, ages and abilities.
          <br />
          <br /> Alejandro’s teaching philosophy is to build a strong foundation
          of instrument/body interaction, theory and high-quality sound as the
          core, while maintaining a steady progression in individual skills
          development. He adjusts his teaching to match your ambition and
          abilities, motivating to stretch beyond what you ever imagined
          possible, helping students to achieve mastery at every level.
          <br />
          <br /> He is educated classical bass player and guitarist from
          Instituto Superior de Arte, Cuba and music pedagogue from
          Musikkhøgskole Norway and at Barratt-Due Musikkinstitutt. Currently
          Alejandro teaches both orchestras and{" "}
          <strong>
            private lessons in contrabass, bass and guitar, either face to face
            (Copenhagen area) or online.
          </strong>
        </p>
      </div>
    );
  }
}
