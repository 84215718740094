import React, { Component } from "react";
// import singing from "../pics/singin.jpeg";
// import logo from "../pics/logito.png";
// import SpotifyPlayer from "react-spotify-web-playback";
import sarah from "../pics/spellemannprisen_winner.png";

import HanneT from "../pics/HanneT.jpeg";

import sarahsol from "../pics/sarahsol.jpeg";
import francis from "../pics/francis.jpeg";

export default class cd extends Component {
  render() {
    return (
      <div className="textsopen">
        <h1 className="prh1">Contributions </h1>

        <div className="prpicscontainer">
          {/* <img
            className="m-2 shadow-sm onhover"
            width="20%"
            alt=""
            src={logo}
            rounded
          /> */}

          <img
            className="m-2 shadow-sm onhover contributions"
            width="20%"
            alt=""
            src={sarah}
            rounded="true"
          />

          <img
            className="m-2 shadow-sm onhover contributions"
            width="20%"
            alt=""
            src={HanneT}
            rounded="true"
          />

          <img
            className="m-2 shadow-sm onhover contributions"
            width="20%"
            alt=""
            src={sarahsol}
            rounded="true"
          />
          <img
            className="m-2 shadow-sm onhover contributions"
            width="20%"
            alt=""
            src={francis}
            rounded="true"
          />
        </div>
      </div>
    );
  }
}
