import React from "react";
import clef from "../pics/bass.png";
// import Texts from "../components/texts";
// import Dropdown from "react-bootstrap/Button";
// import DropdownButton from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import {
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBDropdownItem,
  MDBDropdownLink,
} from "mdb-react-ui-kit";

export default function nav(props) {
  return (
    <>
      <div className="navigationContainer">
        <nav className="navigation">
          <div className="letters">
            <h2>
              Alejandro <img src={clef} alt="" />
              ispert <span className="lettersLittle">Musician</span>
            </h2>
          </div>
          <div className="buttons">
            {/* //MUSIC */}
            <button className=" btns" onClick={props.showsClick}>
              {" "}
              Shows
            </button>
            <MDBDropdown className="dropdowncontainer">
              <MDBDropdownToggle className="btns">Projects</MDBDropdownToggle>
              <MDBDropdownMenu>
                <MDBDropdownItem>
                  <MDBDropdownLink
                    className="dropsColours shorten"
                    // href="#"
                    onClick={props.drengendermistedesinstemmeClick}
                  >
                    Drengen Der Mistede Sin Stemme
                  </MDBDropdownLink>
                </MDBDropdownItem>

                <MDBDropdownItem className="dropsColours">
                  <MDBDropdownLink
                    className="dropsColours shorten"
                    // href="#"
                    onClick={props.spectrumClick}
                  >
                    Cuban Spectrum
                  </MDBDropdownLink>
                </MDBDropdownItem>
                {/* <MDBDropdownItem>
                  <MDBDropdownLink
                    className="dropsColours"
                    href="#"
                    onClick={props.soloClick}
                  >
                    Solo
                  </MDBDropdownLink>
                </MDBDropdownItem> */}

                <MDBDropdownItem>
                  <MDBDropdownLink
                    className="dropsColours shorten"
                    // href="#"
                    onClick={props.lessonsClick}
                  >
                    Lessons
                  </MDBDropdownLink>
                </MDBDropdownItem>

                {/* <MDBDropdownItem>
                  <MDBDropdownLink
                    className="dropsColours shorten"
                    // href="#"
                    onClick={props.culturalprogramsClick}
                  >
                    Cultural Exchange
                  </MDBDropdownLink>
                </MDBDropdownItem> */}
              </MDBDropdownMenu>
            </MDBDropdown>

            {/* //Projects */}
            <MDBDropdown className="dropdowncontainer">
              <MDBDropdownToggle className="btns">Music</MDBDropdownToggle>
              <MDBDropdownMenu>
                <MDBDropdownItem>
                  <MDBDropdownLink
                    className="dropsColours shorten"
                    // href="#"
                    onClick={props.soundClick}
                  >
                    Sound
                  </MDBDropdownLink>
                </MDBDropdownItem>
                <MDBDropdownItem>
                  <MDBDropdownLink
                    className="dropsColours shorten"
                    // href="#"
                    onClick={props.videoClick}
                  >
                    Video
                  </MDBDropdownLink>
                </MDBDropdownItem>

                <MDBDropdownItem>
                  <MDBDropdownLink
                    className="dropsColours shorten"
                    // href="#"
                    onClick={props.cdClick}
                  >
                    Albums
                  </MDBDropdownLink>
                </MDBDropdownItem>
              </MDBDropdownMenu>
            </MDBDropdown>

            {/* //Bio */}
            <MDBDropdown className="dropdowncontainer">
              <MDBDropdownToggle className="btns" onClick={props.bioClick}>
                Bio
              </MDBDropdownToggle>
              <MDBDropdownMenu>
                <MDBDropdownItem>
                  <MDBDropdownLink
                    className="dropsColours shorten"
                    // href="#"
                    onClick={props.prClick}
                  >
                    PR Assets
                  </MDBDropdownLink>
                </MDBDropdownItem>
              </MDBDropdownMenu>
            </MDBDropdown>

            <button className="btns" onClick={props.homeClick}>
              {" "}
              Home
            </button>
          </div>
        </nav>
      </div>
    </>
  );
}
