import React, { Component } from "react";

export default class drengendermistedesinstemme extends Component {
  render() {
    return (
      <div className="texts">
        <h1>Drengen Der Mistede Sin Stemme</h1>

        <p className="paragraf1">
          Proud to present my personal animated story "The Boy Who Lost His
          Voice/ Drengen Der Mistede Sin Stemme/ El Niño Perdió Su Voz". A tale
          told through music and the eyes of a boy who travels across the world,
          looses his voice, and ... the rest you have to see, by attending one
          of my performances 😉
          <br />
          I wish I get a chance to tell my story and inspire kids and families
          who are in between cultures, to break boundaries, and find their
          voice✨
          <br />
          Thank you for supporting, by following and liking my new profiles!
          <br />
          <br />
          <a
            href="https://www.instagram.com/drengendermistedesinstemme/"
            target="_blank"
            rel="noreferrer noopener"
          >
            {" "}
            Drengendermistedesinstemme Instagram profile{" "}
          </a>
          <br />
          <br />
          <a
            href="https://www.facebook.com/people/Drengen-der-mistede-sin-stemme/100095181531705/"
            target="_blank"
            rel="noreferrer noopener"
          >
            {" "}
            Drengendermistedesinstemme Facebook profile{" "}
          </a>
          <br />
          <br />
          <br />
          <br />
          Dansk
          <br />
          Drengen Der Mistede Sin Stemme
          <br />
          En sød, interaktiv musikforestilling om drengen, der flyttede til et
          andet land og mister sin stemme, men alligevel genfinder sig selv og
          sin stemme i en ny og fremmed kultur.
          <br />
          <br />
          Gennem musik og animation fortæller Alejandro ordløst sin historie, om
          at flytte fra Cuba til Norden, og om hvordan mødet med en ny kultur
          kan overraske og give nye toner i livet. I musikken vil man måske
          kunne genkende Cubanske rytmer og nordisk inspiration. Forestillingen
          henvender sig til børn mellem 3-8 år, varer ca. 30 min, og indbyder
          til både fantasi, følelser, fokus, bevægelse, og dans. Især
          børnefamilier, der er landet mellem to kulturer, vil kunne spejle sig
          i fortællingen. Alle er dog velkomne.
          <br />
          <br />
          Alejandro Gispert er musiker og arbejder som musiklærer på skoler i
          København. Han er født og opvokset i Havana, Cuba, hvor han er
          uddannet i kontrabas og guitar. Senere i livet flyttede han til Oslo,
          hvor han bla. spillede skolekoncerter i hele Norge. Efter 10 år,
          landede han i København. Drengen der mistede sin stemme er Alejandros
          egen historie, genfortalt gennem musik og billeder.
        </p>
      </div>
    );
  }
}
