import React, { Component } from "react";

export default class spectrum extends Component {
  render() {
    return (
      <div className="texts">
        <h1>The Cuban Spectrum</h1>

        <p className="paragrafSpectrum">
          The Cuban Spectrum Project is a Caribbean Afro-Cuban-Scandinavian jazz
          crossover band. Book an event of a lifetime, bringing you a musically
          sensory experience that takes you from beating rhythms of Caribbean
          islands, through vivid fjords, to the calmness of chilly Nordic
          nature. The Cuban Spectrum Project band is an exciting fusion of
          lively Caribbean Afro-Cuban beats mixed with melodies derived from
          Scandinavian traditions and melancholy. Cuban composer Alejandro
          Gispert, is the group’s founder and music designer. Together with the
          band he captivates the audience with compositions soaked in rhythm and
          twists of modern Latin jazz.
          <br />
          <br />
          The band is available for shows in Norway and Denmark.
        </p>
        <a
          href={
            "mailto:" +
            "alejandrogispert99@gmail.com" +
            "?subject=Contact%20Form%20Cuban%20Spectrum%20Band&body=Hi%20Alejandro,"
          }
          target="_blank"
          rel="noreferrer"
        >
          <button className="btn spectrumbtn"> Book now</button>
        </a>
      </div>
    );
  }
}
