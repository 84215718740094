import React, { Component } from "react";
import { InstagramEmbed } from "react-social-media-embed";
// import { TikTokEmbed } from "react-social-media-embed";
// import singing from "../pics/singin.jpeg";

// import youtubevid from "../pics//youtubevid.png";

export default class videos extends Component {
  render() {
    return (
      <div className="textsopen">
        <h1 className="prh1">Videos </h1>

        <div className="prpicscontainer">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <InstagramEmbed
              url="https://www.instagram.com/reel/CzZSWVLMJ9t/?igshid=NWp2cmVhMDN6dDNm"
              width={530}
              height={350}
            />

            <InstagramEmbed
              url="https://www.instagram.com/reel/CoiF6v4KiuU/"
              width={530}
              height={350}
            />
          </div>

          <iframe
            width="530"
            height="315"
            src="https://www.youtube.com/embed/8R2glQ4nkLI"
            title="Caribisk"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>

          {/* <a
            href="https://www.youtube.com/watch?v=iFJTDBwCnuA"
            target="_blank"
            rel="noreferrer"
          > */}
          {/* <img
              className="m-2 shadow-sm onhover "
              width="32%"
              alt=""
              src={youtubevid}
              rounded
            />
          </a> */}
          <iframe
            width="530"
            height="315"
            src="https://www.youtube.com/embed/iFJTDBwCnuA"
            title="SEA_Alejandro_contrabass"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>

          <iframe
            width="530"
            height="315"
            src="https://www.youtube.com/embed/C49K95amo1w?start=153"
            title="Morning Song"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>

          {/* <div style={{ display: "flex", justifyContent: "center" }}>
            <TikTokEmbed
              url="https://www.tiktok.com/@alejandrogispert/video/7204484171425762566"
              width={325}
            />
          </div> */}

          <div
            style={{ display: "flex", justifyContent: "center", height: "200" }}
          ></div>

          {/* <div style={{ display: "flex", justifyContent: "center" }}>
            <TikTokEmbed
              url="https://www.tiktok.com/@alejandrogispert/video/7198988379604667653"
              width={325}
            />
          </div> */}
          {/* <iframe
            width="530"
            height="315"
            src="https://www.instagram.com/reel/CpNjbKzqa5g/"
            title="Wicked Song"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe> */}
        </div>
      </div>
    );
  }
}
