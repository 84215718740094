import React, { Component } from "react";
// import singing from "../pics/singin.jpeg";
// import logo from "../pics/logito.png";
// import SpotifyPlayer from "react-spotify-web-playback";
import ReactPlayer from "react-player/lazy";

export default class sound extends Component {
  render(props) {
    return (
      <div className="textsopen">
        <h1 className="prh1">Music and Sound </h1>

        <div>
          <button className="btn" onClick={this.props.onYoutubehandler}>
            on Youtube
          </button>
          <button className="btn" onClick={this.props.onSpotifyhandler}>
            on Spotify
          </button>
        </div>

        <div className="prpicscontainer2">
          {/* <img
            className="m-2 shadow-sm onhover"
            width="20%"
            alt=""
            src={logo}
            rounded
          />
          <img
            className="m-2 shadow-sm onhover"
            width="20%"
            alt=""
            src={logo}
            rounded
          /> */}
          {this.props.onYoutube === true && (
            <div>
              <ReactPlayer
                url={"https://www.youtube.com/watch?v=d0X0qBdT4as"}
                width="50%"
                height="50%"
                controls={true}
              />

              <ReactPlayer
                url={"https://www.youtube.com/watch?v=hNGqDrig2Xw&t=3s"}
                width="50%"
                height="50%"
                controls={true}
              />

              <ReactPlayer
                url={
                  "https://www.youtube.com/watch?v=U3vLeIllSeY&list=OLAK5uy_lhfFn7v-z1um_TWPjxnx_cRX2tFle8eFE"
                }
                width="50%"
                height="50%"
                controls={true}
              />
              <ReactPlayer
                url={
                  "https://www.youtube.com/watch?v=5t53DEsrlkY&list=OLAK5uy_nfgaaf--ckn8DBlie3iPtNtE7AVSlH-6E"
                }
                width="50%"
                height="50%"
                controls={true}
              />
              <ReactPlayer
                url={
                  "https://www.youtube.com/watch?v=jGYa-EjlGwQ&list=OLAK5uy_m1nRmajBqM1Y-1ajUcm9DInNLVmvJtLsw"
                }
                width="50%"
                height="50%"
                controls={true}
              />
            </div>
          )}
        </div>
        {this.props.onSpotify === true && (
          <div className="inspotifymargin">
            <iframe
              title="Beyond Your Dreams"
              styles="border-radius:12px"
              src="https://open.spotify.com/embed/track/4sVFHlU5lDAggPWSHc3TOK?utm_source=generator"
              width="100%"
              height="80"
              frameBorder="0"
              allowFullScreen=""
              allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            ></iframe>

            <iframe
              title="Erin"
              styles="border-radius:12px"
              src="https://open.spotify.com/embed/track/65DkQj3wmPK2IIo7JoVn5R?utm_source=generator"
              width="100%"
              height="80"
              frameBorder="0"
              allowFullScreen=""
              allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            ></iframe>

            <iframe
              title="Changui"
              styles="border-radius:12px"
              src="https://open.spotify.com/embed/track/37gvHPzfipnZdXN4P8aTJf?utm_source=generator"
              width="100%"
              height="80"
              frameBorder="0"
              allowFullScreen=""
              allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            ></iframe>

            <iframe
              title="Rumbadera"
              styles="border-radius:12px"
              src="https://open.spotify.com/embed/track/4LIbP4bJM4LabE62BNkFBP?utm_source=generator"
              width="100%"
              height="80"
              frameBorder="0"
              allowFullScreen=""
              allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            ></iframe>

            <iframe
              title="Away"
              styles="border-radius:12px"
              src="https://open.spotify.com/embed/track/1ybXDZaLOh6rnMmPVbEh1j?utm_source=generator"
              width="100%"
              height="80"
              frameBorder="0"
              allowFullScreen=""
              allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            ></iframe>

            {/* <img
            className="m-2 shadow-sm onhover"
            width="20%"
            alt=""
            src={logo}
            rounded
          /> */}

            {/* <SpotifyPlayer
            token="BQAI_7RWPJuqdZxS-I8XzhkUi9RKr8Q8UUNaJAHwWlpIq6..."
            uris={[
              "https://open.spotify.com/track/65DkQj3wmPK2IIo7JoVn5R?si=2a6930cc6cb246ae",
            ]}
          /> */}
          </div>
        )}
      </div>
    );
  }
}
