import React, { Component } from "react";

export default class bio extends Component {
  render() {
    return (
      <div className="texts">
        {/* <h1>Alejandro Gispert </h1> */}
        {/* <h4>MUSIC EVENTS EXPERIENCES </h4> */}
        <p className="paragraf1">
          Multitalented Cuban/Norwegian Alejandro Gispert is{" "}
          <strong>classically educated bass player</strong> from Instituto
          Superior de Arte in Cuba, with an additional{" "}
          <strong>
            degree in music management, music production, and live electronics
          </strong>
          . He is a recognized{" "}
          <strong>
            award-winning professional composer, bassist, and music manager{" "}
          </strong>
          with bands in Norway and Denmark. Besides being an{" "}
          <strong>active musician for 20 years</strong>, he specializes in
          arranging concerts and shows as a band leader.
          <br />
          <br />
          With <strong>deep roots in the Latin music art</strong>, and strong
          ties to the <strong>Latin jazz and fusion scene</strong>, he has taken
          the Nordics melodies and traditions to heart. Since his teen years, he
          has played with professional ensembles, groups, and bands, like:
          Sandvika Storband, Hovedøen, Yasek Manzano {"&"} trio; Rember Duharte{" "}
          {"&"} Nautilus; Francis del Rio; Hanne Tveter and many more. The
          musical experiences and cultural meetings invade his compositions and
          sound edge. He{" "}
          <strong>is known as a creative, innovative contrabassist</strong> and
          assertive <strong>rhythmical band leader</strong>, who creates{" "}
          <strong>beautiful soundscapes and instrumental productions</strong>.
          <br />
          <br />
          Alejandro’s <strong>stage presence and performances</strong> leads
          both band and audiences safely through profound bass movements to the
          emotionally fragile Nordic tone. He{" "}
          <strong>
            engages listeners with exciting experiences and disruptive music
          </strong>
          .
          <br />
          <br />
          Alejandro is available for individual bookings to play in your band,
          for band performances together with The Cuban Spectrum, and for
          arranging events.
        </p>
        <a
          href={
            "mailto:" +
            "alejandrogispert99@gmail.com" +
            "?subject=Contact%20Form%20Ale`s%20webpage&body=Hi%20Alejandro,"
          }
          target="_blank"
          rel="noreferrer"
        >
          <button className="btn spectrumbtn">Contact</button>
        </a>
      </div>
    );
  }
}
