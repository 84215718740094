import React, { Component } from "react";

export default class solo extends Component {
  render() {
    return (
      <div className="texts">
        <h1>Solo Projects </h1>

        {/* <p className="paragraf1">
          
        </p> */}
      </div>
    );
  }
}
