import React, { Component } from "react";
// import { render } from "react-dom";
import { CgFacebook } from "react-icons/cg";

import { RiLinkedinFill } from "react-icons/ri";
import { CgInstagram } from "react-icons/cg";
import { RiMailSendLine } from "react-icons/ri";
import { BsWhatsapp } from "react-icons/bs";
import ReactWhatsapp from "react-whatsapp";

export default class home extends Component {
  render() {
    return (
      <div className="texts" id="text1">
        <h1 className="hometxt">
          BASS PLAYER {"&"}
          <br /> MUSIC ENTREPRENEUR
        </h1>
        <button
          className="btn btntxt spectrumbtn"
          onClick={this.props.soundClick}
        >
          Stream Here
        </button>

        <div>
          <div>
            <a
              href={
                "mailto:" +
                "alejandrogispert99@gmail.com" +
                "?subject=Contact%20Form%20Ale`s%20webpage&body=Hi%20Alejandro,"
              }
              target="_blank"
              rel="noreferrer"
            >
              <RiMailSendLine className="mediabtns" />{" "}
            </a>
            <a href="https://www.facebook.com/alejandro.gispert/">
              <CgFacebook className="mediabtns" />{" "}
            </a>
          </div>
          <ReactWhatsapp
            className=" whatsapp"
            number="+45-4244-8217"
            message="Hi from Ale`s webpage!"
          >
            {" "}
            <BsWhatsapp />
          </ReactWhatsapp>{" "}
          <a href="https://www.linkedin.com/in/alejandro-gispert/">
            {" "}
            <RiLinkedinFill className="mediabtns" />{" "}
          </a>
          <a href="https://instagram.com/alejandro.gispert?igshid=aG50eW45bGJhcHcz">
            {" "}
            <CgInstagram className="mediabtns" />{" "}
          </a>
        </div>
      </div>
    );
  }
}
